@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes flow {
    0% {
        stroke-dashoffset: 24;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

.animate-flow {
    stroke-dasharray: 12 12;
    animation: flow 1s linear infinite;
}

@keyframes celebrate {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

.animate-celebrate {
    animation: celebrate 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.animate-fade-in {
    animation: fadeIn 0.3s ease-out forwards;
}

@keyframes bounce-gentle {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-4px);
    }
}

.animate-bounce-gentle {
    animation: bounce-gentle 2s ease-in-out infinite;
}

@layer components {
    .prose code {
        @apply bg-gray-100 text-sm px-1.5 py-0.5 rounded font-mono text-gray-800;
    }

    .prose strong {
        @apply font-semibold text-gray-900;
    }

    .prose em {
        @apply text-gray-700 italic;
    }
}